@at-root{
    @font-face {
        font-family: "icon-curtin";
        src: url('../icons/font/icon-curtin.eot?t=1545276917857');
        src: url('../icons/font/icon-curtin.eot?#iefix&t=1545276917857') format('eot'),
            url('../icons/font/icon-curtin.woff?t=1545276917857') format('woff'),
            url('../icons/font/icon-curtin.ttf?t=1545276917857') format('truetype'),
            url('../icons/font/icon-curtin.svg#icon-curtin?t=1545276917857') format('svg');
    }
}

$curtin-icon-set: (
    set-font: "icon-curtin",

    arrow-down: "\E001",

    arrow-left: "\E002",

    arrow-right: "\E003",

    arrow-up: "\E004",

    arrow-wide-down: "\E005",

    arrow-wide-left: "\E006",

    arrow-wide-right: "\E007",

    arrow-wide-up: "\E008",

    certificate: "\E009",

    close: "\E00A",

    correct: "\E00B",

    delete: "\E00C",

    edit: "\E00D",

    error: "\E00E",

    grid: "\E00F",

    info: "\E010",

    key: "\E011",

    map-pin: "\E012",

    menu: "\E013",

    minus: "\E014",

    notes: "\E015",

    play: "\E016",

    plus: "\E017",

    portfolio: "\E018",

    search: "\E019",

    practice: "\E01A",

    templates: "\E01B",

    tick: "\E01C",

    ui-kits: "\E01D",

    warning: "\E01E",

    workbook: "\E01F",
    
);