@import "variables/layout";
@import "curtindesign/elements/button";

.with-icon {
    // Symbolset icon
    @include mq(tablet-small) {
        .ss-icon {
            margin-bottom: -5px; // Adjust the position
            margin-top: 2px;
        }
        span {
            padding-left: $space-x;
        }
    }
}