@import "variables/colour";
@import "variables/border";
@import "mixins/input";
@import "curtindesign/components/accordion";

.accordion__item {    
    margin-bottom: -1px;
    background-color: $body-colour;
    
    &__header{
        a{
            border-top: 1px solid $primary-colour;
            border-radius: 0;
            
            span{
                color: $text-colour;
            }

            &:hover,
            &:focus,
            &:active{
                span{
                    color: $white;
                }
            }
        }
        i{
            color: $black;
        }
    }
    &__content{
        box-shadow: none;
    }

    &:first-child{
        .accordion__item__header{
            a{
                border-radius: 3px 3px 0 0;
            }
        }
    }
    &:last-child{
        .accordion__item__header{
            a{
                border-radius: 0 0 3px 3px;
            }
        }
    }
}

