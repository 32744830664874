@import "variables/colour";
@import "functions/units";
@import "mixins/layout";
@import "mixins/lists";
@import "mixins/icons";
@import "curtindesign/components/site-navi";

// ------------------------------------------------------------------
//   Variables
// ------------------------------------------------------------------

$site-navi-arrow-background-color: $white;

// Level 1
.site-navi {
    @include mq(tablet-land) {
        .site-navi__layout {
            // Level 1
            > li {
                & > a:hover,
                &:hover> a {
                    color: $black;
                    background: $grey10;
                }                
                & > a:focus,
                &:focus> a,
                &> a:active {
                    color: $white;
                    background: $secondary-colour;
                }

                // ------------------------------------------------------------------
                // Level 2 - Single drop down menu
                &.has-subnav {
                    & > div{
                        a{
                            background: $white;
                            color: $list-link-colour;
                            border-top: 1px solid $grey25;
                            border-bottom: 1px solid $grey25;

                            &:focus {
                                border-color: shade($list-link-background-colour-focus, 20);
                                background-color: $list-link-background-colour-focus;
                                color: $list-link-colour-focus;
                                box-shadow: inset 0px 1px 0 tint($list-link-background-colour-focus, 40);
                            }
                            &:hover {
                                border-color: shade($list-link-background-colour-hover, 20);
                                background-color: $list-link-background-colour-hover;
                                color: $list-link-colour-hover;
                                box-shadow: inset 0 1px 0 tint($list-link-background-colour-hover, 30);
                            }
                            &:active {
                                border-color: shade($list-link-background-colour-active, 20);
                                background-color: $list-link-background-colour-active;
                                color: $list-link-colour-active;
                                box-shadow: inset 0px 1px 0 tint($list-link-background-colour-active, 40);
                            }
                        }
                    }                                    
                }
            }
        }
    }
}

// ------------------------------------------------------------------
//   Site dropdown navigation
//   Used in mobile view
// ------------------------------------------------------------------
.site-navi--mobile {
    .site-navi--mobile__navi > li > a,
    .site-navi__close{
        background: $white;
    }
    .site-navi--mobile__navi > li > a{
        &:focus {
            background-color: $list-link-background-colour-focus;
        }
        &:hover {
            background-color: $list-link-background-colour-hover;
        }
        &:active {
            background-color: $list-link-background-colour-active;
        }
    }
    // Level 1
    // ------------------------------------------------------------------
    ul li {
        // Toggle icon - level 1
        .select-toggle {
            border-bottom: 1px solid $grey25;
            border-left: 1px solid $grey25;
            background: $site-navi-arrow-background-color;
            color: $black;
            @include letterpress-black();
            box-shadow: inset 1px 1px 0 tint($grey25, 20);
            &:hover {
                border-color: shade($list-link-background-colour-hover, 20);
                border-left: 1px solid $site-navi-arrow-background-color;
                background-color: $list-link-background-colour-hover;
                color: $list-link-colour-hover;
                box-shadow: inset 1px 1px 0 tint($list-link-background-colour-hover, 40);
            }
            &:focus {
                border-color: shade($list-link-background-colour-focus, 20);
                border-left: 1px solid $site-navi-arrow-background-color;
                background-color: $list-link-background-colour-focus;
                color: $list-link-colour-focus;
                box-shadow: inset 1px 1px 0 tint($list-link-background-colour-focus, 40);
            }
            &:active {
                border-color: shade($list-link-background-colour-active, 20);
                border-left: 1px solid $site-navi-arrow-background-color;
                background-color: $list-link-background-colour-active;
                color: $list-link-colour-active;
                box-shadow: inset 1px 1px 0 tint($list-link-background-colour-active, 40);
            }
        }
        // Toggle icon - if selected, level 1
        &.is-selected> .select-toggle {
            background: $list-link-background-colour-hover;
            border-color: shade($list-link-background-colour-hover, 20);
            color: $list-link-colour-hover;
            box-shadow: inset 1px 1px 0 tint($list-link-background-colour-hover, 40);
            @include letterpress($list-link-colour-hover, 0.3);

            &:focus {
                border-color: shade($list-link-background-colour-active, 20);
                border-left: 1px solid shade($list-link-background-colour-hover, 20);
                background-color: $list-link-background-colour-focus;
                color: $list-link-colour-focus;
                box-shadow: inset 1px 1px 0 tint($list-link-background-colour-active, 40);
            }
            &:active {
                border-color: shade($list-link-background-colour-active, 20);
                border-left: 1px solid shade($list-link-background-colour-hover, 20);
                background-color: $list-link-background-colour-active;
                color: $list-link-colour-active;
                box-shadow: inset 1px 1px 0 tint($list-link-background-colour-active, 40);
            }
        }
        // nav item
        &.is-selected> a {
            background: $list-link-background-colour-hover;
            color: $list-link-colour-hover;
            border-color: shade($list-link-background-colour-hover, 20);
            box-shadow: inset 0 1px 0 tint($list-link-background-colour-hover, 30);
            &:after {
                @include triangle(16px, 8px, down, $list-link-background-colour-hover);
            }
            &:focus {
                border-color: shade($list-link-background-colour-active, 20);
                background-color: $list-link-background-colour-focus;
                color: $list-link-colour-focus;
                box-shadow: inset 0px 1px 0 tint($list-link-background-colour-active, 40);
                &+.select-toggle {
                    border-left: 1px solid shade($list-link-background-colour-active, 20);
                }
                &:after {
                    @include triangle(16px, 8px, down, $list-link-background-colour-active);
                }
            }
            &:active {
                border-color: shade($list-link-background-colour-active, 20);
                background-color: $list-link-background-colour-active;
                color: $list-link-colour-active;
                box-shadow: inset 0px 1px 0 tint($list-link-background-colour-active, 40);
                &+.select-toggle {
                    border-left: 1px solid shade($list-link-background-colour-active, 20);
                }
                &:after {
                    @include triangle(16px, 8px, down, $list-link-background-colour-active);
                }
            }
        }
    }
    // nav item - level 1
    a {
        background: $grey5;
        color: $black;
        border-bottom: none;
        box-shadow: inset 0 1px 0 $grey25;
        &:focus {
            border-color: shade($list-link-background-colour-active, 20);
            background-color: $list-link-background-colour-focus;
            color: $list-link-colour-focus;
            box-shadow: inset 0px 1px 0 tint($list-link-background-colour-active, 40);
            &+.select-toggle {
                border-left: 1px solid shade($list-link-background-colour-active, 20);
            }
        }
        &:hover {
            border-color: shade($list-link-background-colour-hover, 20);
            background-color: $list-link-background-colour-hover;
            color: $list-link-colour-hover;
            box-shadow: inset 0 1px 0 tint($list-link-background-colour-hover, 30);
            &+.select-toggle {
                border-left: 1px solid shade($list-link-background-colour-hover, 20);
            }
        }
        &:active {
            border-color: shade($list-link-background-colour-active, 20);
            background-color: $list-link-background-colour-active;
            color: $list-link-colour-active;
            box-shadow: inset 0px 1px 0 tint($list-link-background-colour-active, 40);
            &+.select-toggle {
                border-left: 1px solid shade($list-link-background-colour-active, 20);
            }
        }
    }
    // Level 2
    // ------------------------------------------------------------------
    ul ul {
        li {
            .select-toggle {
                background: shade($grey5, 2);
                border-color: $grey25;
                box-shadow: inset 1px 1px 0 $grey25;
            }
            // Nav items - level 2 and below
            a {
                border-color: $grey25;
                background: $grey5;
                box-shadow: inset 0 1px 0 $grey25;
            }
            // Toggle icon - if selected, level 2
            &.is-selected> .select-toggle {
                background: tint($list-link-background-colour-hover, 20);
                border-color: shade($list-link-background-colour-hover, 30);
                box-shadow: inset 1px 1px 0 tint($list-link-background-colour-hover, 40);
            }
            // Nav items - if selected, level 2
            &.is-selected> a {
                background: tint($list-link-background-colour-hover, 20);
                box-shadow: inset 0 1px 0 tint($list-link-background-colour-hover, 30);
                // Bottom triangle
                &:after {
                    @include triangle(16px, 8px, down, tint($list-link-background-colour-hover, 20));
                }
            }
            // Top triangle shadow
            &:first-child> a {
                &:before {
                    @include triangle(16px, 8px, down, transparentize($black, 0.8));
                }
            }
        }        
    }

    // Level 3
    // ------------------------------------------------------------------
    ul ul ul li {      
        .select-toggle {
            background: shade($grey10, 2);
        }
        a {
            background: $grey10;
            border-color: $grey25;
            box-shadow: inset 0 1px 0 $grey25;
        }
        // Toggle icon - If selected, level 3
        &.is-selected> .select-toggle {
            background: tint($list-link-background-colour-hover, 40);
            box-shadow: inset 1px 1px 0 tint($list-link-background-colour-hover, 50);
        }
        // Nav items - if selected, level 3
        &.is-selected> a {
            background: tint($list-link-background-colour-hover, 40);
            box-shadow: inset 1px 1px 0 tint($list-link-background-colour-hover, 50);
            // Triangle
            &:after {
                @include triangle(16px, 8px, down, tint($list-link-background-colour-hover, 40));
            }
        }
        // Top triangle shadow
        &:first-child> a {
            &:before {
                @include triangle(16px, 8px, down, transparentize($black, 0.8));
            }
        }
    }

    // Level 4
    // ------------------------------------------------------------------
    ul ul ul ul li {
        // Top triangle shadow
        &:first-child> a {
            &:before {
                @include triangle(16px, 8px, down, transparentize($black, 0.8));
            }
        }
    }
}