@import "variables/colour";
@import "variables/font";
@import "variables/layout";
@import "functions/colour";
@import "functions/units";
@import "mixins/layout";

.step-indicator{
    margin-bottom: $space-l;
    width: 100%;
    padding-right: rem(24px);
    display: table;
    table-layout: fixed;

    &__step{
        display: table-cell;
        box-sizing: border-box;
        position: relative;
        padding-left: rem(27px);
        text-align: center;
        cursor: default;
        vertical-align: top;
        border: none;

        &:hover,
        &:active,
        &:focus{
            border: none;
        }

        &__number{
            font-family: $header-font-family;
            color: $white;
            padding:0;
            position: relative;
            font-size: rem(17px);
            width: rem(36px);
            height: rem(36px);
            line-height: rem(36px);
            background: $primary-colour;
            border-radius: rem(18px);
            text-align: center;
            display: inline-block;
            margin: 0;
            z-index: 2;

            @include mq($max: tablet-small){
                height: rem(30px);
                width: rem(30px);
                line-height: rem(30px);
                font-size: rem(16px);
            }                 
        }

        &__label{
            color: $primary-colour;
            display: block;
            text-decoration: none;
            border-bottom: none;
            font-family: $header-font-family;
            font-size: rem(13px);
            font-weight: $bold;
            margin-top: $space-xs;

            @include mq($max: tablet-small){
                font-size: rem(11px);
                display: inline;                
            }            
        }

        &::after{
            left: 50%;
        }

        &::before{
            left: 0;
        }

        &::before,
        &::after{
            content: " ";
            position: absolute;
            top: 1rem;
            width: 50%;
            height: rem(3px);
            background: $primary-colour;
            z-index:1;
            
            @include mq($max: tablet-small){
                left: rem(14px);
                top: -110%;
                width: rem(3px);
                height: 110%;                
            }
        }

        &:first-child::before, 
        &:last-child::after{
            content: none;
        }

        &.navigatable{
            cursor: pointer;
        }

        &.is-active ~ .step-indicator__step{
            .step-indicator__step__number,
            &::before,
            &::after{
                background: $grey60;
            }
            .step-indicator__step__label{
                color: $black80;
            }          
        }

        &.is-active::after{
            background: $grey60;
        }

        &.hide-label{
            .step-indicator__step__label{
                display: none;
            }
        }

        @include mq($max: tablet-small){
            display: block;
            margin-top: 1.5rem;
            padding-left: 0;
            text-align: left;

            &:first-child{
                margin-top: 0;
            }

            &::after{
                content: none;
            }
        }
    }
}
