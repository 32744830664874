@at-root{
    @font-face {
        font-family: "icon-oasis";
        src: url('../icons/font/icon-oasis.eot?t=1545276919696');
        src: url('../icons/font/icon-oasis.eot?#iefix&t=1545276919696') format('eot'),
            url('../icons/font/icon-oasis.woff?t=1545276919696') format('woff'),
            url('../icons/font/icon-oasis.ttf?t=1545276919696') format('truetype'),
            url('../icons/font/icon-oasis.svg#icon-oasis?t=1545276919696') format('svg');
    }
}

$oasis-icon-set: (
    set-font: "icon-oasis",

    arrow-down: "\E001",

    arrow-left: "\E002",

    arrow-right: "\E003",

    arrow-wide-down: "\E004",

    arrow-up: "\E005",

    arrow-wide-left: "\E006",

    arrow-wide-up: "\E007",

    certificate: "\E008",

    close: "\E009",

    arrow-wide-right: "\E00A",

    correct: "\E00B",

    delete: "\E00C",

    edit: "\E00D",

    email: "\E00E",

    error: "\E00F",

    grid: "\E010",

    info: "\E011",

    key: "\E012",

    map-pin: "\E013",

    menu: "\E014",

    minus: "\E015",

    notes: "\E016",

    pause: "\E017",

    play: "\E018",

    plus: "\E019",

    portfolio: "\E01A",

    practice: "\E01B",

    search: "\E01C",

    templates: "\E01D",

    tick: "\E01E",

    ui-kits: "\E01F",

    warning: "\E020",

    workbook: "\E021",
    
);