// ------------------------------------------------------------------
//   Message colours
// ------------------------------------------------------------------
$message-error-message-colour:                  $red;
$message-error-message-border:                  1px solid $red;
$message-error-message-background-colour:       hsl(hue($red), 70%, 98%);
$message-error-inline-colour:                   $red;
$message-error-inline-border:                   none;
$message-error-inline-background-colour:        inherit;
$message-error-highlight-colour:                $red;
$message-error-highlight-border:                none;
$message-error-highlight-background-colour:     inherit;
$message-error-field-colour:                    $red;
$message-error-field-border:                    1px solid $red;
$message-error-field-background-colour:         hsl(hue($red), 70%, 98%);
$message-error-icon-colour:                     $red;    
$message-error-marker-border:                   none;
$message-error-panel-header-colour:             $white;         
$message-error-panel-header-background-colour:  $red;

$message-success-message-colour:                $green;
$message-success-message-border:                1px solid $green;
$message-success-message-background-colour:     hsl(hue($green), 70%, 98%);
$message-success-inline-colour:                 $green;
$message-success-inline-border:                 none;
$message-success-inline-background-colour:      inherit;
$message-success-highlight-colour:              $green;
$message-success-highlight-border:              none;
$message-success-highlight-background-colour:   inherit;
$message-success-field-colour:                  inherit;
$message-success-field-border:                  1px solid $green;
$message-success-field-background-colour:       hsl(hue($green), 70%, 98%);
$message-success-icon-colour:                   $green;    
$message-success-marker-border:                 none;
$message-success-panel-header-colour:           $white;         
$message-success-panel-header-background-colour:$green;

$message-info-message-colour:                   $blue;
$message-info-message-border:                   1px solid $blue;
$message-info-message-background-colour:        hsl(hue($blue), 70%, 98%);
$message-info-inline-colour:                    $blue;
$message-info-inline-border:                    none;
$message-info-inline-background-colour:         inherit;
$message-info-highlight-colour:                 $blue;
$message-info-highlight-border:                 none;
$message-info-highlight-background-colour:      inherit;
$message-info-field-colour:                     inherit;
$message-info-field-border:                     1px solid $blue;
$message-info-field-background-colour:          hsl(hue($blue), 70%, 98%);
$message-info-icon-colour:                      $blue;    
$message-info-marker-border:                    none;
$message-info-panel-header-colour:              $white;         
$message-info-panel-header-background-colour:   $blue;
   
$message-warning-message-colour:                shade($yellow, 35);
$message-warning-message-border:                1px solid $yellow;
$message-warning-message-background-colour:     hsl(hue($yellow), 70%, 98%);
$message-warning-inline-colour:                 $gold;
$message-warning-inline-border:                 none;
$message-warning-inline-background-colour:      inherit;
$message-warning-highlight-colour:              $gold;
$message-warning-highlight-border:              none;
$message-warning-highlight-background-colour:   inherit;
$message-warning-field-colour:                  inherit;
$message-warning-field-border:                  1px solid $yellow;
$message-warning-field-background-colour:       hsl(hue($yellow), 70%, 98%);
$message-warning-icon-colour:                   $yellow;    
$message-warning-marker-border:                 none;
$message-warning-panel-header-colour:           $black;         
$message-warning-panel-header-background-colour:$yellow;

@import "curtindesign/variables/message";