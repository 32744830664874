@import "mixins/button";
@import "curtindesign/components/page-footer";

.page-footer{
    .copyright {
        .button{
            //change oasis footer feedback button to secondary button
            @include button(
                $colour: $button-colour--secondary,
                $background-colour: $button-background-colour--secondary,
                $background-image: $button-background-image--secondary,
                $border-colour: $button-border-colour--secondary,
                $box-shadow: $button-box-shadow--secondary,
                $text-shadow: $button-text-shadow--secondary,

                $colour-hover: $button-colour-hover--secondary,
                $background-colour-hover: $button-background-colour-hover--secondary,
                $background-image-hover: $button-background-image-hover--secondary,
                $border-colour-hover: $button-border-colour-hover--secondary,
                $box-shadow-hover: $button-box-shadow-hover--secondary,

                $colour-active: $button-colour-active--secondary,
                $background-colour-active: $button-background-colour-active--secondary,
                $background-image-active: $button-background-image-active--secondary,
                $border-colour-active: $button-border-colour-active--secondary,
                $box-shadow-active: $button-box-shadow-active--secondary
            );
        }
    }
}