@import "variables/colour";
@import "variables/border";
@import "variables/button";
@import "variables/layout";
@import "functions/colour";
@import "functions/units";
@import "mixins/layout";
@import "curtindesign/components/global-bar";


// ------------------------------------------------------------------
//   Global bar
// ------------------------------------------------------------------
.global-bar {
    // ------------------------------------------------------------------
    //   Global bar links
    //   Used in tablet landscape view and above
    // ------------------------------------------------------------------
    &__links {
        li {
            border: none;
            box-shadow: none;
            a {
                text-shadow: none;
                @include link-colour($normal: $black, $hover: $yellow, $active: $black, $visited: $black, $focus: $yellow, $border: false); // Link colours
            }
        }
    }

    // ------------------------------------------------------------------
    //   OASIS login links
    //   Used in tablet landscape view and above
    // ------------------------------------------------------------------
    &__login {
        background: none;

        li {
            display: inline;
            margin: 0;
            box-shadow: none;
            border: none;

            a {
                @include link-colour($normal: $black, $hover: $button-colour-hover--secondary, $active: $button-colour-active--secondary, $visited: $black, $focus: $button-colour-hover--secondary, $border: false); // Link colours                
                border: 1px solid $border-colour;
                border-bottom: 1px solid $border-colour !important;
                border-radius: $border-radius;
                padding: $space-xs $space-m;
                text-transform: uppercase;
                text-shadow: none;
                font-family: "GandhiSansBold", Arial;

                &:hover,
                &:focus {
                    // Hover and focus status
                    background-color: $button-background-colour-hover--secondary;
                    border-color: $button-border-colour-hover--secondary; // Overwrite link style
                }
                &:active {
                    // Active status
                    background-color: $button-background-colour-active--secondary;
                    border-color: $button-border-colour-active--secondary; // Overwrite link style
                }
            }
        }
    }
}